import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import './SubSection.css';

interface SubSectionProps {
  children: string | JSX.Element | JSX.Element[]
};

export interface RefType {
  scrollIntoView: () => void
}

export const SubSection = forwardRef<RefType, SubSectionProps>(function SubSection ({ children }, ref) {
  const divRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      divRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }));

  return (
    <div className='sub-section' ref={divRef}>
      {children}
    </div>
  );
});
