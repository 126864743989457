import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface PortfolioCardProps {
  imageUrl: string
  projectName: string
  projectDescription: string
  projectLink: string
};

export const PortfolioCard = ({ imageUrl, projectName, projectDescription, projectLink }: PortfolioCardProps): JSX.Element => {
  const openLink = (url: string): void => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Card className='card-box' color="secondary.main">
      <CardMedia
        sx={{ objectFit: 'contain' }}
        image={imageUrl}
        title={projectName}
        src="image"
        component="img"
      />
      <CardContent>
        <Typography gutterBottom variant="h4">
          {projectName}
        </Typography>
        <Typography
          className='card-description'
          variant="body1"
          color="primary.main"
        >
          {projectDescription}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() => {
            openLink(projectLink);
          }}
          sx={{
            position: 'absolute',
            bottom: 0
          }}
        >
            Link
        </Button>
      </CardActions>
    </Card>
  );
};
