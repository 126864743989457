import React, { type DragEvent } from 'react';
import { Tooltip } from '@mui/material';
import './CompanyButton.css';

interface CompanyButtonProps {
  selectedCompany: string
  setSelectedCompany: React.Dispatch<React.SetStateAction<string>>
  companyName: string
  companyKey: string
  logo: string
};

export const CompanyButton = ({
  selectedCompany,
  setSelectedCompany,
  companyName,
  companyKey,
  logo
}: CompanyButtonProps): JSX.Element => {
  const preventDragHandler = (event: DragEvent): void => {
    event.preventDefault();
  };

  const processImgOutput = (): JSX.Element => {
    const altTag = `${companyKey} logo`;
    const className = companyKey === 'fidelity' ? 'company-tall-icon' : 'company-normal-icon';
    return <img className={className} alt={altTag} src={logo} onDragStart={preventDragHandler} />;
  };

  return (
    <Tooltip title={companyName} arrow>
      <button className={selectedCompany === companyKey ? 'selected-company-logo' : 'company-logo'} onClick={() => { setSelectedCompany(companyKey); }}>
        {processImgOutput()}
      </button>
    </Tooltip>
  );
};
