import React, { type DragEvent } from 'react';
import './SocialMediaButton.css';

interface SocialMediaButtonProps {
  socialMediaName: string
  logo: string
  socialMediaLink: string
};

export const SocialMediaButton = ({ socialMediaName, logo, socialMediaLink }: SocialMediaButtonProps): JSX.Element => {
  const altTag = `${socialMediaName} logo`;

  const preventDragHandler = (event: DragEvent): void => {
    event.preventDefault();
  };

  return (
    <div className='social-media-button-wrapper'>
      <a href={socialMediaLink} target="_blank" rel="noopener noreferrer" className='social-icon-wrapper'>
        <img className='social-icon' alt={altTag} src={logo} onDragStart={preventDragHandler} />
      </a>
      <div className='social-tooltip'>
        <span>{socialMediaName}</span>
      </div>
    </div>
  );
};
