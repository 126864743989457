import React from 'react';
import { Box, Typography } from '@mui/material';
import './CompanyDescription.css';
import { type CompanyDetails } from '../Company';

interface CompanyDescriptionProps {
  companyData: CompanyDetails
};

export const CompanyDescription = ({ companyData }: CompanyDescriptionProps): JSX.Element => {
  const formatProjectsDisplay = (): JSX.Element[] => {
    const projects = companyData.projects;
    const projectOutput = [];
    for (const project in projects) {
      projectOutput.push(
        <div className='project-wrapper' key={project}>
          <Typography variant='h3' sx={{ marginTop: '12px', marginBottom: 0 }}>
            •&emsp;{project}
          </Typography>
          <div className='project-description-wrapper'>
            {projects[project]}
          </div>
        </div>
      );
    }
    return projectOutput;
  };

  return (
    <Box>
      <Typography variant='h3' className='project-detail-header'>
        {companyData.header}
      </Typography>
      <Typography variant='h4' className='project-detail-body'>
        {companyData.role}, {companyData.teamName}
      </Typography>
      <Typography variant='h4' className='project-detail-header'>
        <u>Technologies Used</u>
      </Typography>
      <Typography variant='h4' className='project-detail-body project-detail-technologies'>
        {companyData.technologies}
      </Typography>
      <div className='project-highlight-wrapper'>
        <Typography variant='h4' sx={{ marginTop: 0, marginBottom: '2px' }}>
          Project Highlights
        </Typography>
      </div>
      {formatProjectsDisplay()}
    </Box>
  );
};
