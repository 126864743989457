import { createContext } from 'react';

interface ScreenStateContextType {
  screenState: number | null
  setScreenState: React.Dispatch<React.SetStateAction<number>>
};

const ScreenStateContextState = {
  screenState: 3,
  setScreenState: () => {}
};

export const ScreenStateContext = createContext<ScreenStateContextType>(ScreenStateContextState);
