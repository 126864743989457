import React, { forwardRef } from 'react';
import { type RefType, SubSection } from '../SubSection';
import { PortfolioCard } from './PortfolioCard';
import { PortfolioProjects } from './PortfolioProjects';
import './PortfolioSubSection.css';

export const PortfolioSubSection = forwardRef<RefType, unknown>(function PortfolioSubSection (_, ref) {
  const generatePortfolioCards = (): JSX.Element[] => {
    const projects = [];
    for (const project in PortfolioProjects) {
      const projectData = PortfolioProjects[project];
      const imageUrl = projectData.image;
      const projectDescription = projectData.description;
      const projectLink = projectData.url;

      projects.push(
        <PortfolioCard
          key={imageUrl}
          imageUrl={imageUrl}
          projectName={project}
          projectDescription={projectDescription}
          projectLink={projectLink}
        />
      );
    }
    return projects;
  };

  return (
    <SubSection ref={ref}>
      <div className='portfolio-wrapper'>
        {generatePortfolioCards()}
      </div>
    </SubSection>
  );
});
