import React, { forwardRef, useRef, useState } from 'react';
import { type RefType, SubSection } from '../SubSection';
import { CompanyButton } from './CompanyButton';
import { Box, Fade, Typography } from '@mui/material';
import { CompanyDescription } from './CompanyDescription';
import './WorkSubSection.css';
import { COMPANY } from './Company';

export const WorkSubSection = forwardRef<RefType, unknown>(function WorkSubSection (_, ref) {
  const companyDescriptionRef = useRef(null);
  const [selectedCompany, setSelectedCompany] = useState('');

  const generateCompanyButtons = (): JSX.Element[] => {
    const companyButtonElements: JSX.Element[] = [];
    for (const companyKey in COMPANY) {
      const companyData = COMPANY[companyKey];
      companyButtonElements.push(
        <CompanyButton
          key={companyData.name}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          companyName={companyData.name}
          companyKey={companyKey}
          logo={companyData.logo}
        />
      );
    }
    return companyButtonElements;
  };

  const generateCompanyDescriptionEl = (): JSX.Element | null => {
    if (selectedCompany === '') return null;
    const companyData = COMPANY[selectedCompany];
    return (
      <Fade
        in
        key={selectedCompany}
        exit={false}
        mountOnEnter
        unmountOnExit
      >
        <Box>
          <CompanyDescription key={companyData.logo} companyData={companyData} />
        </Box>
      </Fade>
    );
  };

  return (
    <SubSection ref={ref}>
      <div className='company-list'>
        {generateCompanyButtons()}
      </div>
      <div className='company-description' ref={companyDescriptionRef}>
        {selectedCompany === ''
          ? <Typography variant='h3'>
            Click on a company to view more info
          </Typography>
          : generateCompanyDescriptionEl()}
      </div>
    </SubSection>
  );
});
