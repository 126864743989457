import React, { type DragEvent } from 'react';
import aws_logo from '../../../assets/img/skill_icons/aws.png';
import azure from '../../../assets/img/skill_icons/azure.svg';
import express_logo from '../../../assets/img/skill_icons/express.png';
import django_logo from '../../../assets/img/skill_icons/django.png';
import python_logo from '../../../assets/img/skill_icons/python.png';
import mongodb_logo from '../../../assets/img/skill_icons/mongodb.png';
import postgres_logo from '../../../assets/img/skill_icons/postgres.png';
import react_logo from '../../../assets/img/skill_icons/react.png';
import ts_logo from '../../../assets/img/skill_icons/ts.png';
import './TechStackList.css';

interface logosType {
  React: string
  Typescript: string
  Django: string
  Python: string
  Express: string
  MongoDB: string
  Postgres: string
  AWS: string
  Azure: string
}

export const TechStackList = (): JSX.Element => {
  const logos: logosType = {
    React: react_logo,
    Typescript: ts_logo,
    Django: django_logo,
    Python: python_logo,
    Express: express_logo,
    MongoDB: mongodb_logo,
    Postgres: postgres_logo,
    AWS: aws_logo,
    Azure: azure
  };

  const preventDragHandler = (event: DragEvent): void => {
    event.preventDefault();
  };

  const generateTechIconList = (): JSX.Element[] => {
    const logoElements = [];
    for (const logo in logos) {
      const logoName = logo as keyof logosType;
      if (logoElements.length === 4) {
        logoElements.push(
          <div key={'line-break'} className='tech-icon-line-break' />
        );
      }
      logoElements.push(
        <div key={logoName} className='tech-icon-wrapper'>
          <div className='tech-icon'>
            {logoName === 'MongoDB'
              ? <img className='tech-tall-icon' src={logos[logoName]} onDragStart={preventDragHandler} />
              : <img className='tech-normal-icon' src={logos[logoName]} onDragStart={preventDragHandler} />}
          </div>
          <div className='tech-tooltip'>
            <span>{logoName}</span>
          </div>
        </div>
      );
    }
    return logoElements;
  };

  return (
    <div className='skillset-wrapper'>
      <div className='tech-icon-list'>
        {generateTechIconList()}
      </div>
    </div>
  );
};
