import { Typography } from '@mui/material';
import React from 'react';

import bluevoyant_logo from '../../assets/img/bluevoyant_logo.png';
import klaviyo_logo from '../../assets/img/klaviyo-logo.svg';
import ibm_logo from '../../assets/img/IBM_logo.svg';
import fidelity_logo from '../../assets/img/fidelity-investments-logo.png';
import symbotic_logo from '../../assets/img/SYM.svg';

type CompanyProjects = Record<string, JSX.Element>;

export interface CompanyDetails {
  name: string
  logo: string
  header: string
  role: string
  teamName: string
  technologies: string
  projects: CompanyProjects
};

export type CompanyType = Record<string, CompanyDetails>;

export const COMPANY: CompanyType = {
  bluevoyant: {
    name: 'BlueVoyant',
    logo: bluevoyant_logo,
    header: 'BlueVoyant (May 2023 - Present)',
    role: 'Full-Stack Software Engineer',
    teamName: 'ASAP Engineering',
    technologies: 'Material-UI, React, Express.js, Javascript, Jest, Microsoft Azure, ' +
    'Apollo GraphQL, TypeScript, Golang, Gitlab, PostgreSQL, Figma',
    projects: {
      'BVCDP Mitre Dashboard': (
        <Typography variant='body1'>
          -&emsp;Uses <b><u>Golang</u></b> to build out microservices that define schemas to host <b><u>GraphQL</u></b> subgraphs managed by
          Apollo Federation, retrieve data from a user&apos;s installed <b><u>Microsoft Azure</u></b> Sentinel rules and Splunk
          correlations stored in Elastic, calculate, and return data to a dashboard that informs users about the
          extent of their Azure environment&apos;s coverage by the MITRE ATT&CK Framework<br />
      </Typography>),
      'ASAP Portal': (
        <Typography variant='body1'>
          -&emsp;Utilizes <b><u>Express.js</u></b> to build new REST APIs that wrap around the Azure REST API, while also querying a&nbsp;
          <b><u>PostgreSQL DB</u></b> to retrieve a user&apos;s recorded Azure resources, displaying the Azure resources deployed by
          the user, and detecting if they&apos;re up to date, all within the ASAP portal<br />
          -&emsp;Designs and implements the UI/UX for the ASAP portal using <b><u>React.js</u></b> and <b><u>Material UI</u></b> to allow internal
          users to deploy and update Azure resources and Splunk correlations<br />
        </Typography>
      )
    }
  },
  klaviyo: {
    name: 'Klaviyo',
    logo: klaviyo_logo,
    header: 'Klaviyo (May 2021 - August 2022)',
    role: 'Software Engineer',
    teamName: 'Klaviyo Operations Team',
    technologies: 'React, React Testing Library, TypeScript, JavaScript, ' +
    'Python, Django, Pytest, PostgreSQL, AWS, Heroku, Sentry, Splunk, ' +
    'Graphite, Grafana, Jenkins, GitHub, Figma, Algolia',
    projects: {
      'Klaviyo Support Portal': (
        <Typography variant='body1'>
          -&emsp;Used <b><u>React</u></b> with <b><u>Typescript</u></b> along with
          testing efforts with the  <b><u>React Testing Library</u></b> to create
          Klaviyo&apos;s Support Portal to educate and help Klaviyo users with
          questions related to using the platform<br />
          -&emsp;Created API endpoints in <b><u>Django</u></b> to carry out backend operations that
          such as implementing the Support Portal message form capabilities<br />
      </Typography>),
      'Klaviyo Help Center': (
        <Typography variant='body1'>
          -&emsp;Worked with UI designers through Figma to redesign and develop
          a modernized Klaviyo Help Center search experience using Javascript,
          HTML, and CSS<br />
          -&emsp;Created microservices that ran on Kubernetes pods to handle
          ETL processes to feed data into <b><u>Algolia</u></b> for Help Center querying and
          indexing of articles<br />
        </Typography>
      )
    }
  },
  ibm: {
    name: 'IBM',
    logo: ibm_logo,
    header: 'IBM (January 2020 - April 2021)',
    role: 'Full-Stack Developer',
    teamName: 'IBM Finance and Operations & IBM Financial Crimes Insight',
    technologies: 'React, D3.js, Python, HTML, CSS',
    projects: {
      'Financial Crimes Insight Web Application': (
        <Typography variant='body1'>
          -&emsp;Redesigned and implemented new graphical interfaces into
          IBM&apos;s Financial Crimes Insight web application using <b><u>React</u> </b>
          and <b><u>D3.js</u></b><br />
          -&emsp;Managed current and newly-added Node.js packages used in
          IBM&apos;s Financial Crimes Insight web application to ensure that
          they were compliant for legal use in the application<br />
          -&emsp;Updated legacy <b><u>React</u></b> and IBM&apos;s Carbon Design System
          code in the application to the latest version
        </Typography>
      ),
      'Automated Data Report Tooling': (
        <Typography variant='body1'>
          -&emsp;Used <b><u>Python</u></b> and <b><u>CSS</u></b> from IBM&apos;s Carbon
          Design System to automate and generate <b><u>HTML</u></b> reports for an
          easier view of fraudulent companies within clusters of data
        </Typography>
      )
    }
  },
  fidelity: {
    name: 'Fidelity',
    logo: fidelity_logo,
    header: 'Fidelity Investments (May - August 2018, May - August 2019)',
    role: 'Software Engineering Intern',
    teamName: 'Asset Management Technology & Brokerage Technology QA',
    technologies: 'React, Angular, AWS',
    projects: {
      'Dashboard (Asset Management Technology)': (
        <Typography variant='body1'>
          -&emsp;Developed a timeline-fashioned dashboard to make it easier
          for the DevOps team under the Asset Management Technology organization
          to find <b><u>AWS</u></b> config events linked with their respective
          ServiceNow tickets displayed next to it<br />
          -&emsp;Saved time from manually searching through each source separately,
          especially when paged in the middle of the night<br />
          -&emsp;Dashboard was created with <b><u>Angular 6</u></b> and used
          <b> <u>AWS Cloudformation</u></b> templates and an <b><u>S3</u> </b>
          bucket to provision resources to deploy the application within the
          Fidelity network<br />
        </Typography>
      ),
      'Dashboard (Brokerage Technology QA)': (
        <Typography variant='body1'>
          -&emsp;Produced another dashboard to make it easier for the Brokerage
          Technology QA team to find testing errors from IBM UrbanCode Deploy
          available with a link to the data source in the HP Quality Center to
          look into the test errors<br />
          -&emsp;Dashboard was created with <b><u>React</u></b><br />
        </Typography>
      )
    }
  },
  symbotic: {
    name: 'Symbotic',
    logo: symbotic_logo,
    header: 'Symbotic (January - July 2017)',
    role: 'Software Engineering Co-op',
    teamName: 'Platform Services Team',
    technologies: 'Python, Pytest, Redis, C#, Git, GitLab, Jenkins',
    projects: {
      SwissKnife: (
        <Typography variant='body1'>
          -&emsp;Developed automation scripts that reduced the startup time for
          manually booting up the Symbotic tech stack, essentially reducing the
          time from 10 minutes to 30 seconds<br />
          -&emsp;Created using <b><u>Python</u></b> with <b><u>Redis</u></b> to be the message
          broker that notifies when a piece of software is booting and has finished
          booting to ensure that chronological order of the tech stack is maintained
          during the automation process<br />
          -&emsp;Wrote extensive unit test cases up to 100% code coverage across all
          scripts using <b><u>Pytest</u></b><br />
          -&emsp;Gave first exposure to using <b><u>Git</u></b> and utilizing <b><u>GitLab</u> </b>
          and <b><u>Jenkins</u></b> for CI/CD
        </Typography>
      )
    }
  }
};
