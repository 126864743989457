import React from 'react';
import { SiteLogo } from './SiteLogo';
import { MenuItem } from './MenuItem';
import './SideNavMenu.css';
import { Typography } from '@mui/material';

interface SideNavMenuProps {
  screenState: number
  setScreenStateUpdates: (newState: number) => void
};

export const SideNavMenu = ({ screenState, setScreenStateUpdates }: SideNavMenuProps): JSX.Element => {
  return (
    <div className='side-nav-menu'>
      <SiteLogo />
      <ul className='nav-menu-list'>
        <MenuItem selected={screenState === 3} setScreenStateUpdates={setScreenStateUpdates} menuItemID={3}>
          Introduction
        </MenuItem>
        <MenuItem selected={screenState === 2} setScreenStateUpdates={setScreenStateUpdates} menuItemID={2}>
          Work Experience
        </MenuItem>
        <MenuItem selected={screenState === 1} setScreenStateUpdates={setScreenStateUpdates} menuItemID={1}>
          Portfolio
        </MenuItem>
        <MenuItem selected={screenState === 0} setScreenStateUpdates={setScreenStateUpdates} menuItemID={0}>
          Contact Me
        </MenuItem>
      </ul>
      <div className='nav-tip'>
        <Typography className='nav-tip-msg' variant='body1'>
          * Can also use arrow keys or mouse scroll to navigate
        </Typography>
      </div>
    </div>
  );
};
