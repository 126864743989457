import React from 'react';
import kt_logo from '../../assets/img/kt-logo.png';
import './SiteLogo.css';

export const SiteLogo = (): JSX.Element => {
  return (
    <div className='site-logo-wrapper'>
      <img className='site-logo' src={kt_logo} />
    </div>
  );
};
