import help_center_img from '../../assets/img/portfolio/help_center.png';
import support_portal_img from '../../assets/img/portfolio/support_portal.png';
import recipe_ai_img from '../../assets/img/portfolio/recipe_ai.png';
import secret_sky_img from '../../assets/img/portfolio/secret_sky.png';

interface ProjectDescription {
  image: string
  description: string
  url: string
};

type PortfolioList = Record<string, ProjectDescription>;

export const PortfolioProjects: PortfolioList = {
  'Klaviyo Help Center': {
    image: help_center_img,
    description: 'Made with Javascript, HTML, CSS, and an ETL process ' +
      'modularized into microservices while written in Python. ' +
      'Amazon EKS used to host kubernetes pods for microservices.',
    url: 'https://help.klaviyo.com/hc/en-us'
  },
  'Klaviyo Support Portal': {
    image: support_portal_img,
    description: 'Used React and TypeScript for ' +
      'the frontend, Python and Django for the backend, along with ' +
      'Pytest and React Testing Library for extensive unit, integration ' +
      'and end-to-end tests that are run through Jenkins pipelines for CI/CD.',
    url: 'https://www.klaviyo.com/login?next=/support'
  },
  RecipeAI: {
    image: recipe_ai_img,
    description: 'Created a dockerized full-stack application to ' +
      "generate and store food recipes from using OpenAI's " +
      'Completions API. Made with MongoDB, Express, React, Node.js, ' +
      'and Docker.',
    url: 'https://github.com/kodycode/RecipeAI'
  },
  'Secret Sky Client': {
    image: secret_sky_img,
    description: 'Reverse-engineered and modded the browser client for ' +
      "Active Theory's Secret Sky to run without the need for a " +
      'live server instance. Source not available as ' +
      'Active Theory has requested to keep the custom client private. ',
    url: 'https://youtu.be/lsE5eCVXvKw'
  }
};
