import React, { useEffect, useRef } from 'react';
import { IntroSubSection } from './IntroSubSection';
import { type RefType } from './SubSection';
import { PortfolioSubSection } from './PortfolioSubSection';
import { ContactSubSection } from './ContactSubSection';
import './Section.css';
import { WorkSubSection } from './WorkSubSection';
import { Box } from '@mui/material';

interface SectionProps {
  screenState: number
}

export const Section = ({ screenState }: SectionProps): JSX.Element => {
  const screenStateRef = useRef(screenState);
  const IntroSubSectionRef = useRef<RefType>(null);
  const WorkSubSectionRef = useRef<RefType>(null);
  const PortfolioSubSectionRef = useRef<RefType>(null);
  const ContactSubSectionRef = useRef<RefType>(null);
  const isResizeAttached = useRef(false);

  const setSectionView = (screenState: number): void => {
    switch (screenState) {
      case 3:
        IntroSubSectionRef.current?.scrollIntoView();
        break;
      case 2:
        WorkSubSectionRef.current?.scrollIntoView();
        break;
      case 1:
        PortfolioSubSectionRef.current?.scrollIntoView();
        break;
      case 0:
        ContactSubSectionRef.current?.scrollIntoView();
        break;
    };
  };

  useEffect(() => {
    screenStateRef.current = screenState;
    setSectionView(screenState);
    if (isResizeAttached.current) return;

    // Fixes the screen getting stuck when scrolling and resizing
    let timeoutId: number | ReturnType<typeof setTimeout> | null = null;
    const resizeListener = (event: Event): void => {
      clearTimeout(timeoutId as number);
      timeoutId = setTimeout(() => {
        if (screen.width >= 950) {
          setSectionView(screenStateRef.current);
        }
      }, 150);
    };
    isResizeAttached.current = true;
    window.addEventListener('resize', resizeListener);
  }, [screenState]);

  return (
    <Box maxWidth={'xl'} className='section-content'>
      <div style={{ height: '100%' }}>
        <IntroSubSection ref={IntroSubSectionRef} />
        <WorkSubSection ref={WorkSubSectionRef} />
        <PortfolioSubSection ref={PortfolioSubSectionRef} />
        <ContactSubSection ref={ContactSubSectionRef} />
      </div>
    </Box>
  );
};
