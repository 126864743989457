import React, { forwardRef } from 'react';
import { type RefType, SubSection } from '../SubSection';
import { TechStackList } from './TechStackList';
import './IntroSubSection.css';
import { Typography } from '@mui/material';

export const IntroSubSection = forwardRef<RefType, unknown>(function IntroSubSection (_, ref) {
  return (
    <SubSection ref={ref}>
      <div className='intro-wrapper'>
        <div className="intro-header">
          <Typography variant="h1">
            <u>Kody Thach</u>,
          </Typography>
        </div>
        <div className="intro-body">
          <Typography variant="h2">
            a <b><u>full-stack engineer</u>,</b>
          </Typography>
          <Typography variant="h2">
            and <b><u>front-end</u></b> expert
          </Typography>
        </div>
        <TechStackList />
      </div>
    </SubSection>
  );
});
