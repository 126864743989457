import React, { type MouseEvent } from 'react';
import './MenuItem.css';

interface MenuItemProps {
  selected: boolean
  children: string
  setScreenStateUpdates: (newState: number) => void
  menuItemID: number
}

export const MenuItem = ({ selected, children, setScreenStateUpdates, menuItemID }: MenuItemProps): JSX.Element => {
  const selectMenuItem = (event: MouseEvent<HTMLLIElement>): void => {
    if (selected) return;
    setScreenStateUpdates(menuItemID);
  };

  return (
    <li className={selected ? 'selected-menu-item' : 'menu-item'} onClick={selectMenuItem}>
      {children}
    </li>
  );
};
