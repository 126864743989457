import React, { forwardRef } from 'react';
import { type RefType, SubSection } from '../SubSection';
import { SocialMediaButton } from './SocialMediaButton';
import gmail_logo from '../../assets/img/gmail.png';
import linkedin_logo from '../../assets/img/linkedin_logo.png';
import nexus_mods_logo from '../../assets/img/nexus_mods.png';
import github_logo from '../../assets/img/github-mark.png';
import resume_logo from '../../assets/img/resume.png';
import './ContactSubSection.css';

export const ContactSubSection = forwardRef<RefType, unknown>(function ContactSubSection (_, ref) {
  return (
    <SubSection ref={ref}>
      <div className='contact-section-wrapper'>
        <div className='contact-section-header'>
          <h1>
            For questions or inquiries, feel free to
            reach out at any of the below:
          </h1>
        </div>
        <div className='social-media-list'>
          <SocialMediaButton
            socialMediaName={'Gmail'}
            logo={gmail_logo}
            socialMediaLink="mailto:kodythach@gmail.com"
          />
          <SocialMediaButton
            socialMediaName={'LinkedIn'}
            logo={linkedin_logo}
            socialMediaLink="https://www.linkedin.com/in/kodythach/"
          />
          <SocialMediaButton
            socialMediaName={'Resume'}
            logo={resume_logo}
            socialMediaLink={'/Kody_Thach_Resume.pdf'}
          />
          <SocialMediaButton
            socialMediaName={'GitHub'}
            logo={github_logo}
            socialMediaLink="https://github.com/kodycode"
          />
          <SocialMediaButton
            socialMediaName={'Nexus Mods'}
            logo={nexus_mods_logo}
            socialMediaLink="https://www.nexusmods.com/users/24389679"
          />
        </div>
      </div>
    </SubSection>
  );
});
