import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ScreenStateContext } from './AppContexts';
import { SideNavMenu } from './SideNavMenu';
import { Container } from '@mui/material';
import { Section } from './Section';
import './App.css';

function App (): JSX.Element {
  const scrollCooldown = 200;
  const effectUsed = useRef(false);
  const screenStateRef = useRef(3);
  const isScrollingRef = useRef(false);
  const lastScrollTime = useRef(0);
  const scrollBarRef = useRef<HTMLDivElement | null>(null);
  const scrollBarIndicatorRef = useRef<HTMLDivElement | null>(null);
  const touchstartX = useRef(0);
  const touchstartY = useRef(0);
  const touchendX = useRef(0);
  const touchendY = useRef(0);
  const [screenState, setScreenState] = useState(3);

  const handleKeyScreenChange = useCallback((event: KeyboardEvent): void => {
    switch (event.key) {
      case 'ArrowUp':
        incrementScreenState();
        break;
      case 'ArrowDown':
        decrementScreenState();
        break;
    }
  }, []);

  const handleScrollScreenChange = useCallback((event: WheelEvent): void => {
    event.preventDefault();
    const deltaY = event.deltaY;
    determineScreenStateChange(deltaY);
  }, []);

  const determineScreenStateChange = (deltaY: number): void => {
    if (!isScrollingRef.current && isScrollCooldownDone()) {
      if (deltaY < 0) {
        incrementScreenState();
      } else {
        decrementScreenState();
      }
      isScrollingRef.current = true;
      setTimeout(function () { isScrollingRef.current = false; }, 750);
    }
    lastScrollTime.current = Date.now();
  };

  const isScrollCooldownDone = (): boolean => {
    const timeSinceScroll = Date.now() - lastScrollTime.current;
    if (timeSinceScroll >= scrollCooldown) {
      return true;
    }
    return false;
  };

  const incrementScreenState = (): void => {
    if (screenStateRef.current + 1 < 4) {
      screenStateRef.current++;
      setScreenState(screenStateRef.current);
      setScrollbarIndicator(screenStateRef.current);
    }
  };

  const decrementScreenState = (): void => {
    if (screenStateRef.current - 1 >= 0) {
      screenStateRef.current--;
      setScreenState(screenStateRef.current);
      setScrollbarIndicator(screenStateRef.current);
    }
  };

  const setScrollbarIndicator = (newScreenState: number): void => {
    const normalizedScreenState = 3 - newScreenState;
    const currentIndicator = scrollBarRef.current?.children.item(normalizedScreenState) as HTMLDivElement;
    currentIndicator.style.backgroundColor = '#BB0A21';
    if (scrollBarIndicatorRef.current != null) {
      scrollBarIndicatorRef.current.style.backgroundColor = '#fbfbfb';
    }
    scrollBarIndicatorRef.current = currentIndicator;
  };

  const setScreenStateUpdates = (newState: number): void => {
    screenStateRef.current = newState;
    setScreenState(newState);
    setScrollbarIndicator(newState);
  };

  const preventDefaultBehavior = (event: Event): void => {
    if ('key' in event) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };

  const saveInitialTouchPos = (event: TouchEvent): void => {
    touchstartX.current = event.changedTouches[0].screenX;
    touchstartY.current = event.changedTouches[0].screenY;
  };

  const processCoordinates = (event: TouchEvent): void => {
    touchendX.current = event.changedTouches[0].screenX;
    touchendY.current = event.changedTouches[0].screenY;
    handleGesture();
  };

  const handleGesture = (): void => {
    if (window.matchMedia('(hover: none) and (pointer: coarse) and (orientation: landscape)').matches) {
      // Threshold to meet for a swipe to register as left/right as diagonal swipes
      // can end up putting benig mistake for up/down swipes
      const swipeThreshold = Math.abs(touchendX.current - touchstartX.current);
      if (swipeThreshold < 100) return;

      if (touchendX.current < touchstartX.current) {
        determineScreenStateChange(1);
      } else if (touchendX.current > touchstartX.current) {
        determineScreenStateChange(-1);
      }
    } else {
      if (touchendY.current < touchstartY.current) {
        determineScreenStateChange(1);
      } else if (touchendY.current > touchstartY.current) {
        determineScreenStateChange(-1);
      }
    }
  };

  useEffect(() => {
    if (effectUsed.current) return;
    setScrollbarIndicator(3);
    document.addEventListener('keydown', preventDefaultBehavior, false);
    document.addEventListener('keyup', handleKeyScreenChange, false);
    document.addEventListener('DOMMouseScroll', preventDefaultBehavior, false);
    document.addEventListener('mousewheel', preventDefaultBehavior, false);
    document.addEventListener('wheel', handleScrollScreenChange, { passive: false });
    document.addEventListener('touchstart', saveInitialTouchPos, false);
    document.addEventListener('touchend', processCoordinates, false);
    effectUsed.current = true;
  }, []);

  return (
    <>
      <div className='scrollbar' ref={scrollBarRef}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='app'>
        <Container maxWidth='xl' disableGutters sx={{ display: 'flex' }}>
            <ScreenStateContext.Provider value={{ screenState, setScreenState }}>
              <SideNavMenu screenState={screenState} setScreenStateUpdates={setScreenStateUpdates} />
              <Section screenState={screenState} />
            </ScreenStateContext.Provider>
        </Container>
      </div>
    </>
  );
}

export default App;
