import { createTheme } from '@mui/material/styles';

const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 950,
      lg: 1080,
      xl: 1350
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fbfbfb',
          color: '#192841'
        },
        arrow: {
          color: '#fbfbfb'
        },
        popper: {
          caretColor: 'transparent'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#BB0A21',
          '&:hover': {
            backgroundColor: '#b0b0b0'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#fbfbfb',
          color: '#192841'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          color: '#192841'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#192841'
    },
    secondary: {
      main: '#fbfbfb'
    },
    mode: 'light'
  },
  transitions: {
    duration: {
      enteringScreen: 450,
      leavingScreen: 100
    }
  }
});

appTheme.typography.h1 = {
  caretColor: 'transparent',
  fontSize: 'clamp(1.6rem, 0.8133333333333334rem + 7.866666666666668vw, 7.5rem)',
  '@media (max-width: 950px)': {
    fontSize: 'clamp(1.6rem, 0.40506329113924044rem + 11.949367088607596vw, 7.5rem)'
  },
  '@media (max-width: 600px)': {
    fontSize: 'clamp(1.6rem, 0.8133333333333334rem + 7.866666666666668vw, 7.5rem)'
  },
  '@media (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)': {
    fontSize: 'clamp(1.6rem, 0.8133333333333334rem + 7.866666666666668vh, 7.5rem)'
  }
};

appTheme.typography.h2 = {
  fontWeight: 'normal',
  caretColor: 'transparent',
  fontSize: 'clamp(0.8rem, 0.4066666666666667rem + 3.933333333333334vw, 3.75rem)',
  '@media (max-width: 950px)': {
    fontSize: 'clamp(0.8rem, 0.20253164556962022rem + 5.974683544303798vw, 3.75rem)'
  },
  '@media (max-width: 600px)': {
    fontSize: 'clamp(0.8rem, 0.4066666666666667rem + 3.933333333333334vw, 3.75rem)'
  },
  '@media (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)': {
    fontSize: 'clamp(0.8rem, 0.4066666666666667rem + 3.933333333333334vh, 3.75rem)'
  }
};

appTheme.typography.h3 = {
  caretColor: 'transparent',
  fontSize: 'clamp(0.8rem, 0.76rem + 0.4000000000000001vw, 1.1rem)',
  '@media (max-width: 950px) and (hover:hover), (min-width: 600px) and (max-width: 950px)': {
    fontSize: 'clamp(0.8rem, 0.7475409836065574rem + 0.5245901639344264vh, 1.1rem)'
  },
  '@media (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)': {
    fontSize: 'clamp(0.8rem, 0.76rem + 0.4000000000000001vh, 1.1rem)'
  }
};

appTheme.typography.h4 = {
  caretColor: 'transparent',
  fontSize: 'clamp(0.81rem, 0.7873333333333334rem + 0.2266666666666666vw, 0.98rem)',
  '@media (max-width: 950px) and (hover:hover), (min-width: 600px) and (max-width: 950px)': {
    fontSize: 'clamp(0.81rem, 0.7802732240437159rem + 0.2972677595628414vh, 0.98rem)'
  },
  '@media (hover: none) and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)': {
    fontSize: 'clamp(0.81rem, 0.7873333333333334rem + 0.2266666666666666vh, 0.98rem)'
  }
};

appTheme.typography.body1 = {
  caretColor: 'transparent',
  fontSize: 'clamp(0.6rem, 0.5373333333333333rem + 0.6266666666666668vw, 1.07rem)',
  '@media (max-width: 950px) and (hover:hover), (min-width: 600px) and (max-width: 950px)': {
    fontSize: 'clamp(0.6rem, 0.5178142076502732rem + 0.8218579234972678vh, 1.07rem)'
  }
};

export const AppTheme = appTheme;
